import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import NativeElementListener from "./native_element_value_listener";
import UnifiedSearchAutocompleteComponent from "./unified_search_autocomplete_component";

const guestOption = { name: "The report is for guest", value: "GUEST", id: "GUEST" };

const ClientSearchAutoComplete = (props) => {
  const {
    input_field_name,
    selected_client_name,
    selected_client_id,
    is_client_pages,
    is_users_active = "all",
    show_unknown_client_option: showUnknownClientOption,
    show_the_report_is_for_guest: showTheReportIsForGuest,
    is_for_guest: isForGuestSelected
  } = props;

  const [selectedClientName, setSelectedClientName] = useState(
    showUnknownClientOption ? "Unknown Client" : ""
  );
  const [selectedClientId, setSelectedClientId] = useState(selected_client_id);
  const [allClients, setAllClients] = useState([]);
  const clientAutocompleteIdRef = useRef(null);

  // This is only used when listen_location_change_input_id is passed in.
  const [location, setLocation] = useState("");

  // Redirect to specific client page if on client pages
  const onSelectRedirectToSpecificClient = (id) => {
    if (is_client_pages) {
      window.location = `/clients/${id}`;
    }
  };

  // Load clients for autocomplete list
  const loadClientsAutoCompleteList = () => {
    const fetchUrl = `/clients/client_autocomplete_authorized_clients.json?all_clients=${
      is_client_pages ? "true" : "false"
    }`;
    axios
      .get(fetchUrl)
      .then((response) => {
        let filteredClients = response.data.filter((client) => {
          if (is_users_active === "true" && !is_client_pages) return !client.inactive;
          if (is_users_active === "false" && !is_client_pages) return client.inactive;
          if (!is_users_active || is_users_active === "all" || is_client_pages) return true;

          return false;
        });

        setAllClients(filteredClients);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    loadClientsAutoCompleteList();
  }, []);

  // Generate options for the select component
  const options = useMemo(() => {
    const createClientOption = (client) => {
      let clientName = `${client.first_name} ${client.last_name}`;
      let location = client.location;
      let inactive = client.inactive;
      let name = clientName;
      let ignored = client.ignored;

      if (location && is_client_pages) {
        name += ` (${location})`;
      }
      if (inactive && is_client_pages) {
        name += " - Inactive";
      }
      if (ignored && is_client_pages) {
        name += " ---Ignored---";
      }
      return {
        name: name,
        value: clientName,
        id: client.id,
        location: location,
        inactive: inactive
      };
    };

    const clientsToDisplay = location
      ? allClients.filter((client) => client.location === location)
      : allClients;

    const clientOptions = clientsToDisplay.map(createClientOption);

    if (showTheReportIsForGuest) {
      clientOptions.push(guestOption);
    }

    if (showUnknownClientOption) {
      clientOptions.push({ name: "Unknown Client", value: "", id: "" });
    }

    return clientOptions;
  }, [allClients, location, showUnknownClientOption]);

  // Determine the selected client based on selectedClientId
  const selectClient = useMemo(() => {
    if (showUnknownClientOption && !selectedClientId) {
      return null;
    }
    if (selectedClientId === guestOption.id) {
      return guestOption;
    }
    if (selectedClientId) {
      return allClients.find((allClient) => +allClient.id === +selectedClientId);
    }

    return null;
  }, [selectedClientId, allClients]);

  // Update hidden input value when selectClient or selectedClientId changes
  useEffect(() => {
    if (clientAutocompleteIdRef.current) {
      clientAutocompleteIdRef.current.value = selectClient ? selectClient.id : "";
    }
  }, [selectClient, selectedClientId]);

  // Set initial selected client name based on props
  useEffect(() => {
    if (selected_client_name) {
      setSelectedClientName(selected_client_name);
    }
    if (showTheReportIsForGuest && isForGuestSelected) {
      setSelectedClientName(guestOption.name);
    }
  }, []);

  const handleClearClick = () => {
    // Handle clear click logic here
    setSelectedClientName("");
    setSelectedClientId("");
  };

  const handleOnSelectedOptionChange = (option) => {
    // Handle selected option change logic here
    setSelectedClientName(option?.name || "");
    setSelectedClientId(option?.id || "");
  };

  const handleDropdownItemClick = (option) => {
    if (option?.id) {
      onSelectRedirectToSpecificClient(option.id);
    }
  };

  return (
    <div className="client-search-autocomplete">
      {props.listen_location_change_input_id && (
        <NativeElementListener
          listenId={props.listen_location_change_input_id}
          setFieldValue={setLocation}
        />
      )}
      <UnifiedSearchAutocompleteComponent
        input_field_name={input_field_name}
        dropdownStyle={{
          width: "100%"
        }}
        selected_name={selectedClientName}
        inputFieldId="client-search-input"
        inputFieldPlaceholder="Search for a client..."
        options={options}
        hardCodedOptions={[]}
        handleInputFocus={() => {
          loadClientsAutoCompleteList();
        }}
        handleClearClick={handleClearClick}
        handleOnSelectedOptionChange={handleOnSelectedOptionChange}
        handleDropdownItemClick={handleDropdownItemClick}
      />
      <input
        data-explorator_test_id="todo_list_new_page_client_name_"
        ref={clientAutocompleteIdRef}
        name={"client_autocomplete_id"}
        type={"hidden"}
      />
      <HiddenInput showUnknownClientOption selectClient={selectClient} />
    </div>
  );
};

const HiddenInput = ({ selectClient }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const value = selectClient ? JSON.stringify(selectClient) : "";
    if (inputRef.current) {
      inputRef.current.value = value;
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  }, [selectClient]);

  /* The hidden input fields are created so other native components can get the selected client data. */
  return <input id="client_autocomplete_selected_client" type="hidden" ref={inputRef} />;
};

export default ClientSearchAutoComplete;

ClientSearchAutoComplete.propTypes = {
  input_field_name: PropTypes.string,
  selected_client_name: PropTypes.string,
  selected_client_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  is_client_pages: PropTypes.bool,
  is_users_active: PropTypes.string,
  listen_location_change_input_id: PropTypes.string,
  show_unknown_client_option: PropTypes.bool,
  show_the_report_is_for_guest: PropTypes.bool,
  is_for_guest: PropTypes.bool
};

HiddenInput.propTypes = {
  selectClient: PropTypes.object || null
};
